import { FetchResult, useLazyQuery, useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { createContext } from 'use-context-selector';

import { CREATE_UNIT } from '@components/molecules/form/form-upsert-unit/mutation';
import { INotificationFeedbackContent } from '@utils/constants/common';
import { GET_UNITS_QUERY, GET_MEASURING_POINT_QUERY } from './queries';
import { UnitPayload, IUnitsManagement, UnitMeasuringPayload } from './types';
import { UnitsManager, MeasuringPointManager } from './manager';
import { DELETE_UNIT, UPDATE_UNIT, UPSERT_MEASURING_POINT } from './mutation';
import { parserUnitManagement } from './parser';

export * from './types';

export type UnitContextType = {
  getUnits: () => void;
  setGroupId: React.Dispatch<React.SetStateAction<string>>;
  createUnitHandler: (unit: UnitPayload) => Promise<{ id: string | null }>;
  upsertMeasuringPointHandler: (unit: UnitMeasuringPayload) => Promise<{ id: string | null }>;
  updateUnitHandler: (unit: UnitPayload) => Promise<{ id: string | null }>;
  deleteUnitHandler: (unit: UnitPayload) => Promise<FetchResult<any>>;
  setNotificationResponse: (content: INotificationFeedbackContent) => void;
  setMessageNotificationFeedback: React.Dispatch<React.SetStateAction<INotificationFeedbackContent | undefined>>;
  messageNotificationFeedBack: INotificationFeedbackContent | undefined;
  setOpenNotificationFeedback: React.Dispatch<React.SetStateAction<boolean>>;
  openNotificationFeedBack: boolean;
  groupName: string;
  units: UnitPayload[];
  loading: boolean;
};

export const UnitContext = createContext({} as UnitContextType);

interface Provider {
  children: React.ReactNode;
}

const UnitsProvider: React.FC<Provider> = ({ children }: Provider) => {
  const [units, setUnits] = useState<Array<UnitPayload>>([]);
  const [unitsManagement, setUnitsManagement] = useState<Array<IUnitsManagement>>([]);
  const [groupId, setGroupId] = useState<string>('');
  const [groupName, setGroupName] = useState<string>('');
  const [messageNotificationFeedBack, setMessageNotificationFeedback] = React.useState<INotificationFeedbackContent>();
  const [openNotificationFeedBack, setOpenNotificationFeedback] = React.useState(false);

  const [getUnits, { called, loading }] = useLazyQuery(GET_UNITS_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const manager = new UnitsManager(data);
      setUnits(manager.units);
      setGroupName(manager.rawData.group.name);
    },
    onError: () => {
      setUnits([]);
    },
    variables: {
      groupId,
    },
  });

  const [getMeasuringPoint] = useLazyQuery(GET_MEASURING_POINT_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const manager = new MeasuringPointManager(data, units);
      setUnits(manager.units);
    },
    onError: () => {
      setUnits([]);
    },
    variables: {
      groupId,
    },
  });

  const [createUnit, { loading: loadingCreate }] = useMutation(CREATE_UNIT, {
    fetchPolicy: 'network-only',
  });
  const [upsertMeasuringPoint] = useMutation(UPSERT_MEASURING_POINT, {
    fetchPolicy: 'network-only',
  });
  const [updateUnit, { loading: loadingUpdate }] = useMutation(UPDATE_UNIT, {
    fetchPolicy: 'network-only',
  });
  const [deleteUnit] = useMutation(DELETE_UNIT, {
    fetchPolicy: 'network-only',
  });

  if (!called && groupId) getUnits();

  function createUnitHandler(unit: UnitPayload) {
    return createUnit({
      variables: {
        input: {
          groupId: groupId,
          name: unit.name,
          legalName: unit.legalName,
          averageInvoiceAmount: unit.averageInvoiceAmount,
          unitType: unit.unitType,
          tariffSubgroup: unit.tariffSubgroup,
          tariffModality: unit.tariffModality,
          contractedDemandOffPeak: unit.contractedDemandOffPeak,
          contractedDemandPeak: unit.contractedDemandPeak,
          contractedDemandOffPeakBeforeMigration: unit.contractedDemandOffPeakBeforeMigration,
          contractedDemandPeakBeforeMigration: unit.contractedDemandPeakBeforeMigration,
          edc: unit.edc,
          docType: unit.docType,
          docNumber: unit.docNumber,
          submarket: unit.submarket,
          migrationDate: unit.migrationDate,
          expectedMigrationDate: unit.expectedMigrationDate,
          powerGenerator: unit.powerGenerator,
          assetCode: unit.assetCode,
          state: unit.state,
        },
      },
    })
      .then((response) => {
        return { id: response.data['createUnit']['id'] };
      })
      .catch(() => {
        return { id: null };
      });
  }

  function upsertMeasuringPointHandler(payload: UnitMeasuringPayload) {
    return upsertMeasuringPoint({
      variables: {
        input: { ...payload },
      },
    })
      .then((response) => {
        return { id: response.data['id'] };
      })
      .catch(() => {
        return { id: null };
      });
  }

  function updateUnitHandler(unit: UnitPayload) {
    return updateUnit({
      variables: {
        input: {
          groupId: groupId,
          id: unit.id,
          edc: unit.edc,
          name: unit.name,
          legalName: unit.legalName,
          unitType: unit.unitType,
          tariffSubgroup: unit.tariffSubgroup,
          tariffModality: unit.tariffModality,
          averageInvoiceAmount: unit.averageInvoiceAmount,
          contractedDemandOffPeak: unit.contractedDemandOffPeak,
          contractedDemandPeak: unit.contractedDemandPeak,
          contractedDemandOffPeakBeforeMigration: unit.contractedDemandOffPeakBeforeMigration,
          contractedDemandPeakBeforeMigration: unit.contractedDemandPeakBeforeMigration,
          docType: unit.docType,
          docNumber: unit.docNumber,
          submarket: unit.submarket,
          migrationDate: unit.migrationDate,
          expectedMigrationDate: unit.expectedMigrationDate,
          powerGenerator: unit.powerGenerator,
          assetCode: unit.assetCode,
          state: unit.state,
        },
      },
    })
      .then((response) => {
        return { id: response.data['createUnit']['id'] };
      })
      .catch(() => {
        return { id: null };
      });
  }

  function deleteUnitHandler(unit: UnitPayload) {
    return deleteUnit({
      variables: {
        unitId: unit.id,
      },
    });
  }

  const setNotificationResponse = (content: INotificationFeedbackContent) => {
    setMessageNotificationFeedback(content);
    setOpenNotificationFeedback(true);
  };

  useEffect(() => {
    if (units) {
      const parsedUnitsManagement = parserUnitManagement(units, groupId);
      setUnitsManagement(parsedUnitsManagement);
    }
  }, [units]);

  useEffect(() => {
    if (units && groupId) {
      getMeasuringPoint();
    }
  }, [units, groupId]);

  return (
    <UnitContext.Provider
      value={{
        setGroupId,
        getUnits,
        createUnitHandler,
        upsertMeasuringPointHandler,
        deleteUnitHandler,
        updateUnitHandler,
        groupName,
        units,
        setNotificationResponse,
        setMessageNotificationFeedback,
        messageNotificationFeedBack,
        setOpenNotificationFeedback,
        openNotificationFeedBack,
        loading: loading || loadingCreate || loadingUpdate,
      }}
    >
      {children}
    </UnitContext.Provider>
  );
};

export default UnitsProvider;
