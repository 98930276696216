import React, { useState } from 'react';
import { FetchResult, useLazyQuery, useMutation } from '@apollo/client';
import { createContext } from 'use-context-selector';

import { GET_GROUP_DRIVE_DATA_QUERY, GET_GROUPS_DATA_QUERY } from './queries';
import { CREATE_COMMERCIAL_GROUP, UPDATE_COMMERCIAL_GROUP, UPSERT_GROUP_DRIVE_FOLDER } from './mutation';
import { CommercialGroupPayload, CommercialGroup, GroupDrivePayload } from './types';
import { CommercialGroupsManager } from './manager';

export * from './types';

export type CommercialGroupContextType = {
  getCommercialGroups: () => void;
  getGroupDriveFolderHandler: (groupId: string) => Promise<string>;
  createCommercialGroupHandler: (
    values: CommercialGroupPayload,
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
  updateCommercialGroupHandler: (
    values: CommercialGroupPayload,
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
  upsertGroupDriveFolderHandler: (
    values: GroupDrivePayload,
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
  deleteCommercialGroupHandler: (
    id: string,
    deletedAt: string,
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
  commercialGroups: CommercialGroup[];
  loading: boolean;
  loadingDriveData: boolean;
};

export const CommercialGroupContext = createContext({} as CommercialGroupContextType);

interface Provider {
  children: React.ReactNode;
}

const CommercialGroupsProvider: React.FC<Provider> = ({ children }: Provider) => {
  const [commercialGroups, setCommercialGroups] = useState<Array<CommercialGroup>>([]);
  const [createCommercialGroup, { loading: loadingCreation }] = useMutation(CREATE_COMMERCIAL_GROUP, {
    fetchPolicy: 'network-only',
  });
  const [updateCommercialGroup, { loading: loadingUpdate }] = useMutation(UPDATE_COMMERCIAL_GROUP, {
    fetchPolicy: 'network-only',
  });

  const [upsertGroupDriveFolder, { loading: loadingUpsertFolder }] = useMutation(UPSERT_GROUP_DRIVE_FOLDER, {
    fetchPolicy: 'network-only',
  });

  const [getCommercialGroups, { loading }] = useLazyQuery(GET_GROUPS_DATA_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const manager = new CommercialGroupsManager(data);

      setCommercialGroups(manager.commercialGroups);
    },
    onError: () => {
      setCommercialGroups([]);
    },
  });

  const [getGroupDriveFolderId, { loading: loadingDriveData }] = useLazyQuery(GET_GROUP_DRIVE_DATA_QUERY, {
    fetchPolicy: 'network-only',
  });

  function createCommercialGroupHandler({ docNumber, docType, legalName, name, type }: CommercialGroupPayload) {
    return createCommercialGroup({
      variables: {
        input: {
          name,
          legalName,
          docType,
          docNumber,
          type,
        },
      },
    });
  }

  function updateCommercialGroupHandler({ id, docNumber, docType, legalName, name, type }: CommercialGroupPayload) {
    return updateCommercialGroup({
      variables: {
        input: {
          id,
          name,
          legalName,
          docNumber,
          docType,
          type,
        },
      },
    });
  }

  function deleteCommercialGroupHandler(id: string, deletedAt: string) {
    return updateCommercialGroup({
      variables: {
        input: {
          id: id,
          deletedAt,
        },
      },
    });
  }

  const upsertGroupDriveFolderHandler = ({ id, targetFolderId }: GroupDrivePayload) => {
    return upsertGroupDriveFolder({
      variables: {
        input: {
          commercialGroupId: id,
          targetFolderId,
        },
      },
    });
  };

  const getGroupDriveFolderHandler = async (groupId: string) => {
    try {
      const { data } = await getGroupDriveFolderId({ variables: { groupId } });
      return data.getGroupFolderInternalStorage.targetFolderId;
    } catch (error) {
      return '';
    }
  };

  return (
    <CommercialGroupContext.Provider
      value={{
        getCommercialGroups,
        commercialGroups,
        loading: loading || loadingCreation || loadingUpdate || loadingUpsertFolder,
        loadingDriveData,
        createCommercialGroupHandler,
        updateCommercialGroupHandler,
        deleteCommercialGroupHandler,
        upsertGroupDriveFolderHandler,
        getGroupDriveFolderHandler,
      }}
    >
      {children}
    </CommercialGroupContext.Provider>
  );
};

export default CommercialGroupsProvider;
